<template>
  <div>
    <location />
    <div class="page wrap-1200">
      <b-form
        @submit="onSubmit"
        class="filter-group row row-cols-2 row-cols-sm-auto g-2 justify-content-end"
      >
        <div class="col">
          <select
            aria-label="Select Mini Game Type"
            name="type"
            v-model="searchData.type"
          >
            <option value="0">{{ $t("filter.common.total") }}</option>
            <option value="1">{{ $t("filter.common.holeinone") }}</option>
            <option value="2">{{ $t("filter.common.albatross") }}</option>
            <option value="3">{{ $t("filter.common.eagle_p5") }}</option>
            <option value="4">{{ $t("filter.common.birdie_p5") }}</option>
            <option value="5">{{ $t("filter.common.par_p5") }}</option>
            <option value="6">{{ $t("filter.common.eagle_p4") }}</option>
            <option value="7">{{ $t("filter.common.birdie_p4") }}</option>
            <option value="8">{{ $t("filter.common.par_p4") }}</option>
            <option value="9">{{ $t("filter.common.longest") }}</option>
            <option value="10">{{ $t("filter.common.nearpin") }}</option>
            <option value="11">
              {{ $t("filter.common.longest_ranking") }}
            </option>
            <option value="12">
              {{ $t("filter.common.nearpin_ranking") }}
            </option>
          </select>
        </div>
        <div class="col">
          <select
            aria-label="Select one"
            name="searchtype"
            v-model="searchData.searchtype"
          >
            <option value="1">{{ $t("filter.common.title") }}</option>
            <!-- <option value="2">매장명</option> -->
            <option value="3">{{ $t("filter.common.course") }}</option>
            <option value="4">{{ $t("filter.common.winner") }}</option>
          </select>
        </div>
        <div class="col-12">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              name="searchname"
              v-model="searchData.searchname"
            />
            <button
              class="btn btn-primary btn-md icon-search"
              type="submit"
            ></button>
          </div>
        </div>
      </b-form>

      <table class="table board toggle-xxl">
        <thead>
          <tr>
            <th class="d-xxl-table-cell text-start">
              {{ $t("title.common.title") }}
            </th>
            <!-- <th class="d-none d-xxl-table-cell">
              {{ $t("title.common.type") }}
            </th> -->
            <th class="d-xxl-table-cell">
              {{ $t("title.common.winner") }}
            </th>
            <th class="d-none d-md-table-cell">
              {{ $t("title.common.location") }}
            </th>
            <th class="d-none d-xl-table-cell">
              {{ $t("title.common.course") }}({{ $t("title.common.hole") }})
            </th>
            <!-- <th class="d-none d-xxl-table-cell">
              {{ $t("title.common.hole") }}
            </th> -->
            <th class="d-none d-sm-table-cell">
              {{ $t("title.common.prizes") }}
            </th>
            <th class="d-none d-sm-table-cell">
              {{ $t("title.common.distance") }}
            </th>
            <th class="d-none d-xxl-table-cell">
              {{ $t("title.common.date") }}
            </th>
            <th class="th-arrow">&nbsp;</th>
          </tr>
        </thead>
        <tbody v-if="data_yn">
          <template v-for="(item, index) in items">
            <tr :key="index">
              <td class="d-xxl-table-cell text-start">
                <span class="fs-12 text-capitalize text-pink">{{
                  event_type(item.type)
                }}</span
                ><br />
                <span class="text-black">{{ item.title }}</span>
              </td>
              <!-- <td class="d-none d-xxl-table-cell">
                {{ event_type(item.type) }}
              </td> -->
              <td class="d-xxl-table-cell">
                <MemberInfo
                  :nickname="item.nickname"
                  :playerno="item.playerno"
                />
              </td>
              <td class="d-none d-md-table-cell shop-name">
                {{ item.shopname }}
              </td>
              <td class="d-none d-xl-table-cell">
                {{ item.coursename }} {{ item.subcoursename }}({{
                  item.hole + "H"
                }})
              </td>
              <!-- <td class="d-none d-xxl-table-cell">
                {{ item.hole + "H" }}
              </td> -->
              <td class="d-none d-sm-table-cell text-point">
                {{ item.type < 11 ? null || item.point : "Rank Prize" }}
              </td>
              <td class="d-none d-sm-table-cell">
                {{ item.record && item.type > 8 ? item.record + "m" : "-" }}
              </td>
              <td class="d-none d-xxl-table-cell text-date">
                <template>
                  {{ item.timezone_date.timezone_common | dateformat }}
                </template>
              </td>
              <td class="td-arrow" @click="toggle_tr(index)">
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide === index }"
                />
              </td>
            </tr>
            <tr
              class="d-xxl-none"
              :class="{ show: view_hide === index }"
              :key="index + 'r'"
              v-if="view_hide === index"
            >
              <td colspan="6">
                <div class="toggle-content">
                  <!-- <div class="row">
                    <div class="col-4">{{ $t("title.common.type") }}</div>
                    <div class="col-8">{{ event_type(item.type) }}</div>
                  </div> -->
                  <div class="row d-md-none">
                    <div class="col-4">{{ $t("title.common.location") }}</div>
                    <div class="col-8 shop-name">
                      {{ item.shopname }}
                    </div>
                  </div>

                  <div class="row d-xl-none">
                    <div class="col-4">{{ $t("title.common.course") }}</div>
                    <div class="col-8">
                      {{ item.coursename }} {{ item.subcoursename }}
                    </div>
                  </div>

                  <div class="row d-xl-none">
                    <div class="col-4">{{ $t("title.common.hole") }}</div>
                    <div class="col-8">
                      {{ item.hole + "H" }}
                    </div>
                  </div>

                  <div class="row d-sm-none">
                    <div class="col-4">{{ $t("title.common.prizes") }}</div>
                    <div class="col-8 text-point">{{ item.point }}</div>
                  </div>

                  <div class="row d-sm-none">
                    <div class="col-4">{{ $t("title.common.distance") }}</div>
                    <div class="col-8 text-point">
                      {{
                        item.record && item.type > 8 ? item.record + "m" : "-"
                      }}
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-4">{{ $t("title.common.date") }}</div>
                    <div class="col-8 text-date">
                      <template>
                        {{ item.timezone_date.timezone_common | dateformat }}
                      </template>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7" class="text-center">
              {{ $t("text.common.no_data") }}
            </td>
          </tr>
        </tbody>
      </table>

      <TermDefinition getname="event" />

      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Page navigation"
        v-if="page_total > 1"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import TermDefinition from "@/components/TermDefinition";
import ax from "@/api/event";
import { myMixin } from "@/mixin/myMixin";
import "@/filter/common";

export default {
  name: "WinAMiniGame",
  components: {
    location,
    TermDefinition,
  },
  data() {
    return {
      searchData: {
        pageno: parseInt(this.$route.query.page) || 1,
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
        searchtype:
          this.$route.query.searchtype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchtype) ||
          1,
        type:
          this.$route.query.type ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].type) ||
          0,
        eventno: 0,
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],

      eventwvinninglistcnt: 0,
      view_hide: null,
    };
  },

  methods: {
    get_eventwinninglist() {
      this.view_hide = null;
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_eventwinninglist(this.searchData, (flag, data) => {
        // console.log(flag, data);
        if (flag) {
          if (data.eventwvinninglistcnt <= 0) {
            this.data_yn = false;
            // return false;
          } else {
            this.data_yn = true;
          }
          this.total_cnt = data.eventwvinninglistcnt;
          this.page_total = Math.ceil(data.eventwvinninglistcnt / this.rows);
          this.items = data.eventwvinninglist;
        } else {
          this.data_yn = false;
          this.page_total = 0;
          alert(data.message);
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router.push({
        query: {
          searchname: this.searchData.searchname,
          searchtype: this.searchData.searchtype,
          type: this.searchData.type,
        },
      });
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.get_eventwinninglist();
  },
  watch: {
    "searchData.searchname": function () {
      this.searchData.pageno = 1;
      this.get_eventwinninglist();
    },
    "searchData.searchtype": function () {
      this.searchData.pageno = 1;
      this.get_eventwinninglist();
    },
    "searchData.type": function () {
      this.searchData.pageno = 1;
      this.get_eventwinninglist();
    },

    "searchData.pageno": function () {
      this.get_eventwinninglist();
    },
  },
  mixins: [myMixin],
};
</script>
