<template>
  <div>
    <location />
    <div class="page wrap-1200">
      <h3 class="subtitle">
        <span class="mb-2 icon badge bg-pink text-capitalize">
          {{ event_type(eventinfo.award_fk) }} </span
        ><br />
        {{ eventinfo.title }}
      </h3>

      <b-form
        @submit="onSubmit"
        class="filter-group row row-cols-1 row-cols-sm-auto g-2"
      >
        <div class="col">
          <select
            aria-label="Select one"
            name="searchtype"
            v-model="searchData.searchtype"
          >
            <option value="P.nickname">
              {{ $t("title.common.winner") }}
            </option>
            <option value="O.nickname">
              {{ $t("title.common.location") }}
            </option>
          </select>
        </div>
        <div class="col">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              name="searchname"
              v-model="searchData.searchname"
            />
            <button
              class="btn btn-primary btn-md icon-search"
              type="submit"
            ></button>
          </div>
        </div>
      </b-form>

      <table class="table board toggle-xl">
        <thead>
          <tr>
            <th class="d-xl-table-cell">
              {{ $t("title.common.winner") }}
            </th>
            <th class="d-none d-lg-table-cell">
              {{ $t("title.common.date") }}
            </th>
            <th class="d-none d-sm-table-cell">
              {{ $t("title.common.location") }}
            </th>
            <th class="d-none d-xl-table-cell">
              {{ $t("title.common.course") }}({{ $t("title.common.hole") }})
            </th>
            <!-- <th class="d-none d-xl-table-cell">
              {{ $t("title.common.hole") }}
            </th> -->
            <th class="d-xl-table-cell">
              {{ $t("title.common.prizes") }}
            </th>
            <th class="d-none d-xl-table-cell" v-if="eventinfo.award_fk > 8">
              {{ $t("title.common.distance") }}
            </th>
            <th class="th-arrow">&nbsp;</th>
          </tr>
        </thead>
        <tbody v-if="data_yn">
          <template v-for="(item, index) in items">
            <tr :key="index">
              <td class="d-xl-table-cell">
                <MemberInfo
                  :nickname="item.nickname"
                  :playerno="item.playerno"
                />
              </td>
              <td class="d-none d-lg-table-cell text-date">
                <template>
                  {{ item.timezone_date.timezone_common | dateformat }}
                </template>
              </td>
              <td class="d-none d-sm-table-cell shop-name">
                {{ item.shopname }}
              </td>
              <td class="d-none d-xl-table-cell">
                {{ item.coursename }} {{ item.subcoursename }}({{
                  item.hole + "H"
                }})
              </td>
              <!-- <td class="d-none d-xl-table-cell">
                {{ item.hole + "H" }}
              </td> -->
              <td class="d-xl-table-cell text-point">
                {{ item.point }}
              </td>
              <td class="d-xl-table-cell d-none" v-if="eventinfo.award_fk > 8">
                <template v-if="item.record">{{ item.record }}m</template>
                <template v-else>-</template>
              </td>
              <td class="td-arrow" @click="toggle_tr(index)">
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide === index }"
                />
              </td>
            </tr>
            <tr
              class="d-xl-none"
              :class="{ show: view_hide === index }"
              :key="index + 'r'"
              v-if="view_hide === index"
            >
              <td colspan="5">
                <div class="toggle-content">
                  <div class="row d-lg-none">
                    <div class="col-4">{{ $t("title.common.date") }}</div>
                    <div class="col-8 text-date">
                      <template>
                        {{ item.timezone_date.timezone_common | dateformat }}
                      </template>
                    </div>
                  </div>

                  <div class="row d-sm-none">
                    <div class="col-4">{{ $t("title.common.location") }}</div>
                    <div class="col-8 shop-name">
                      {{ item.shopname }}
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-4">{{ $t("title.common.course") }}</div>
                    <div class="col-8">
                      {{ item.coursename }} {{ item.subcoursename }}
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-4">{{ $t("title.common.hole") }}</div>
                    <div class="col-8">
                      {{ item.hole + "H" }}
                    </div>
                  </div>
                  <div class="row d-sm-none" v-if="eventinfo.award_fk > 8">
                    <div class="col-4">{{ $t("title.common.distance") }}</div>
                    <div class="col-8" v-if="item.record">
                      {{ item.record }}m
                    </div>
                    <div class="col-8" v-else>-</div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6" class="text-center">
              {{ $t("text.common.no_data") }}
            </td>
          </tr>
        </tbody>
      </table>

      <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="page_total"
        v-model="searchData.pageno"
        use-router
        aria-label="Page navigation"
        v-if="page_total > 1"
        first-class="prev"
        prev-class="prev"
        next-class="next"
        last-class="next"
        hide-ellipsis
        limit="10"
      >
        <template #first-text>
          <i class="material-icons">keyboard_double_arrow_left</i>
        </template>
        <template #prev-text>
          <i class="material-icons">navigate_before</i>
        </template>
        <template #next-text>
          <i class="material-icons">navigate_next</i>
        </template>
        <template #last-text>
          <i class="material-icons">keyboard_double_arrow_right</i>
        </template>
        <template #page="{ page, active }">
          <a v-if="active">{{ page }}</a>
          <a v-else>{{ page }}</a>
        </template>
      </b-pagination-nav>

      <div class="btn-bottom-wrapper">
        <b-button
          size="xl"
          variant="outline-primary"
          :aria-label="$t('button.common.list')"
          @click="$router.push({ name: 'MiniGame' }).catch(() => {})"
        >
          {{ $t("button.common.list") }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/event";
import { myMixin } from "@/mixin/myMixin";
import "@/filter/common";

export default {
  name: "WinAMiniGameList",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        pageno:
          this.$route.query.pageno ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].pageno) ||
          1,
        searchname:
          this.$route.query.searchname ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchname) ||
          "",
        searchtype:
          this.$route.query.searchtype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchtype) ||
          "P.nickname",
        type:
          this.$route.query.type ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].type) ||
          0,
        eventno: this.$route.params.eventno || 0,
      },
      page_total: 1,
      total_cnt: 0,
      data_yn: true,
      rows: 10,
      items: [],
      eventinfo: {},
      eventwvinninglistcnt: 0,
      view_hide: null,
    };
  },
  watch: {
    "searchData.searchname": function () {
      this.searchData.pageno = 1;
      this.get_eventwinninglist();
    },
    "searchData.searchtype": function () {
      this.searchData.pageno = 1;
      this.get_eventwinninglist();
    },
    "searchData.type": function () {
      this.searchData.pageno = 1;
      this.get_eventwinninglist();
    },

    "searchData.pageno": function () {
      this.get_eventwinninglist();
    },
  },

  methods: {
    get_eventwinninglist() {
      this.view_hide = null;
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_eventwinninglist(this.searchData, (flag, data) => {
        // console.log(flag, data);
        if (flag) {
          if (data.eventwvinninglistcnt <= 0) {
            this.data_yn = false;
            // return false;
          } else {
            this.data_yn = true;
          }
          this.total_cnt = data.eventwvinninglistcnt;
          this.page_total = Math.ceil(data.eventwvinninglistcnt / this.rows);
          this.items = data.eventwvinninglist;
          this.eventinfo = data.eventinfo;
        } else {
          this.data_yn = false;
          this.page_total = 0;
          alert(data.message);
        }
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.pageno = 1;
      this.$router.push({
        query: {
          searchname: this.searchData.searchname,
          searchtype: this.searchData.searchtype,
          type: this.searchData.type,
        },
      });
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
  },
  created() {
    this.get_eventwinninglist();
  },
  mixins: [myMixin],
};
</script>
